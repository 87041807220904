import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const Location = () => {
  return (
    <button>
      <FontAwesomeIcon icon={faLocationDot} />
    </button>
  );
};

export default Location;
