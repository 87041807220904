import {useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../utils/axios";
import { Notification } from "../../types/types";

export const useGetNotifications = () => {
  return useQuery({
    queryKey: ["notifications"],
    queryFn: async (): Promise<Notification[]> => {
      const { data } = await axios.get("/Notification/GetNotifications");
      return data;
    },
    staleTime: 1000 * 60 * 5,
    refetchInterval: 5000,
  });
};

export const useMarkNotificationAsDeleted = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (notification: Notification) => {
      return axios.put("Notification/SetNotificationDeleted", notification, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
  });
};

export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async() => {
      return axios.put("Notification/SetNotificationRead", {}, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
  });
};