import { useMutation } from "@tanstack/react-query";
import axios from "../../utils/axios";
import { SwapRequest, TimetableEventRest } from "../../types/types";

export const useCreateSwapRequest = () => {
  return useMutation({
    mutationFn: async ({ eventFrom, eventTo }: { eventFrom: TimetableEventRest; eventTo: TimetableEventRest }) => {
      const response = await axios.post("EventSwapRequest/CreateEventSwapRequest", { eventFrom, eventTo });
      return response;
    },
  });
};

export const useGetAllUsersSwapRequest = () => {
  return useMutation({
    mutationFn: async (): Promise<SwapRequest[]> => {
      const { data } = await axios.get("EventSwapRequest/UserRequests");
      return data;
    },
  });
};

export const useDeleteSwapRequest = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await axios.delete(`EventSwapRequest/${id}`);
      return response;
    },
  });
};