import React from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Grid from "./Grid";
import { colHeadings, rowHeadings } from "../../src/utils/static/constants";
import { TimetableEvent } from "../types/types";

type TimetableProps = {
  events: TimetableEvent[];
  addEvents: (newEvents: TimetableEvent[]) => void;
  removeEvents: (oldEvents: TimetableEvent[]) => void;
};

const Timetable: React.FC<TimetableProps> = ({ events, addEvents, removeEvents }) => {
  return events ? <Grid rows={rowHeadings} columns={colHeadings} items={events} onAddEvents={addEvents} onRemoveEvents={removeEvents} /> : <></>;
};

export default Timetable;
