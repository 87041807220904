import { useEffect, useRef, useState, useMemo } from "react";
import { AppBar, Toolbar, IconButton, Badge, Box, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Settings from "@mui/icons-material/Settings";
import QuestionMark from "@mui/icons-material/QuestionMark";
import SwapifyLogo from "../assets/images/logowhite.png";
import LoginModal from "./Modals/LoginModal";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PrintIcon from "@mui/icons-material/Print";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Sidebar from "./Sidebar";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useGetAllEventsOfCourse } from "../hooks/queries/CourseQuery";
import { useGetDownloadTimeTable } from "../hooks/queries/EventQuery";
import { TimetableEvent } from "../types/types";
import NotificationsDropdown from "./NotificationsDropdown";

type NavbarProps = {
  events: TimetableEvent[];
  onAddEvents: (newEvents: TimetableEvent[]) => void;
  onRemoveEvents: (oldEvents: TimetableEvent[]) => void;
  onSetDefaultState: (sideEvents: TimetableEvent[]) => void;
};

const Navbar: React.FC<NavbarProps> = ({ events, onAddEvents, onRemoveEvents, onSetDefaultState }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const [notificationAnchorEl, setNotificationAnchorEl] = useState<null | HTMLElement>(null);
  const [sideEvents, setSideEvents] = useState<TimetableEvent[]>([]);
  const [isOriginalTimetable, setIsOriginalTimetable] = useState(true);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const originalEvents = useMemo(() => events, [events]);
  const getAllEventsOfCourse = useGetAllEventsOfCourse();
  const getAllEventsOfCourseRef = useRef(getAllEventsOfCourse);

  useEffect(() => {
    let resultEvents: TimetableEvent[] = [];
    const originalEventCodes: Set<string> = new Set(originalEvents.map((event) => event.course.code));

    const fetchEvents = async () => {
      for (const code of originalEventCodes) {
        try {
          const data = await getAllEventsOfCourseRef.current.mutateAsync(code);

          const sideEventList = Array.isArray(data)
            ? data
            : Array.isArray((data as any)?.timetableEvents)
              ? (data as any).timetableEvents
              : [];

          resultEvents = [...resultEvents, ...sideEventList];
        } catch (error) {
          console.error("Error fetching course events:", error);
        }
      }

      setSideEvents(resultEvents);
    };

    fetchEvents();
  }, [originalEvents]);

  const handleAddEvents = (newEvents: TimetableEvent[]) => {
    onAddEvents(newEvents);
  };

  const handleRemoveEvents = (oldEvents: TimetableEvent[]) => {
    onRemoveEvents(oldEvents);
  };

  const setIsOriginalTimetableFn = (isOriginal: boolean) => {
    setIsOriginalTimetable(isOriginal);
  };

  (window as any).setIsOriginalTimetable = setIsOriginalTimetableFn;

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const { mutate: downloadTimetable } = useGetDownloadTimeTable();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handlePrintPDF = async () => {
    const scheduleElement = document.querySelector("#schedule");

    if (!scheduleElement) {
      console.error("Schedule element not found.");
      return;
    }

    try {
      const canvas = await html2canvas(scheduleElement as HTMLElement);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("landscape", "mm", "a4");

      const margin = 10;
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
      const pdfHeight = pdf.internal.pageSize.getHeight() - 2 * margin;
      const spacingBelowTable = 5;

      const canvasAspectRatio = canvas.width / canvas.height;
      const pdfAspectRatio = pdfWidth / pdfHeight;

      let imgWidth, imgHeight;

      if (canvasAspectRatio > pdfAspectRatio) {
        imgWidth = pdfWidth;
        imgHeight = pdfWidth / canvasAspectRatio;
      } else {
        imgHeight = pdfHeight;
        imgWidth = imgHeight * canvasAspectRatio;
      }

      const imgX = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
      const imgY = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

      pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);

      const copyrightMessage = "© 2024 Žilinská univerzita v Žiline. Všetky práva vyhradené.";
      const fontSize = 10;
      const textY = imgY + imgHeight + spacingBelowTable;

      pdf.setFontSize(fontSize);
      pdf.text(
        copyrightMessage,
        pdf.internal.pageSize.getWidth() / 2,
        textY,
        { align: "center" }
      );

      pdf.save("schedule.pdf");
    } catch (error) {
      console.error("Failed to generate PDF:", error);
    }
  };

  const handleNotificationsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNotificationAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profil</MenuItem>
      <MenuItem onClick={handleMenuClose}>Môj účet</MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <a href={process.env.REACT_APP_LOGOUT_URL}>Odhlásiť sa</a>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 17 new notifications" color="inherit" onClick={handleNotificationsOpen}>
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profil</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#5ebed7" }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open sidebar"
            sx={{ mr: 2 }}
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <img alt={"Logo"} src={SwapifyLogo} height="12%" width="12%" />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {!isOriginalTimetable && (
              <Box sx={{ bgcolor: "darkgray", borderRadius: 2, alignSelf: "center" }}>
                <button
                  className="bg-gray-200 px-5 py-2 rounded-lg shadow-md font-bold hover:bg-gray-300 border border-gray-400"
                  onClick={() => {
                    onSetDefaultState(sideEvents);
                    (window as any).resetSelectedBlock();
                    (window as any).resetSelectedCourses();
                    setIsOriginalTimetable(true);
                  }}
                >
                  SPÄŤ NA ROZVRH
                </button>
              </Box>
            )}
            <IconButton size="large" color="inherit" aria-label="print" onClick={() => handlePrintPDF()}>
              <PrintIcon />
            </IconButton>
            <IconButton size="large" color="inherit" aria-label="download" onClick={() => downloadTimetable()}>
              <FileDownloadIcon />
            </IconButton>
            <button onClick={handleModalOpen}>
              <ModeEditIcon />
            </button>
            <IconButton size="large" color="inherit">
              <QuestionMark />
            </IconButton>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit" onClick={handleNotificationsOpen}>
              <Badge badgeContent={unreadCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" color="inherit">
              <Settings />
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <LoginModal isOpen={modalIsOpen} onRequestClose={handleModalClose} />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} onAddEvents={handleAddEvents} onRemoveEvents={handleRemoveEvents} />
      <NotificationsDropdown
        anchorEl={notificationAnchorEl}
        onClose={handleNotificationsClose}
        setUnreadCount={setUnreadCount}
      />
    </Box>
  );
};

export default Navbar;
