import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMarkNotificationAsDeleted } from "../hooks/queries/NotificationQuery";
import { Notification } from "../types/types";

interface NotificationCardProps {
  notification: Notification;
}

const NotificationCard: React.FC<NotificationCardProps> = ({ notification }) => {
  const { title, message, createdAt } = notification;
  const formattedDate = new Date(createdAt).toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const { mutate } = useMarkNotificationAsDeleted();

  const handleMarkAsDeleted = () => {
    mutate({ ...notification, isDeleted: true });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={1}
      mb={1}
      sx={{
        borderRadius: 1,
        boxShadow: 1,
        backgroundColor: "white",
        "&:hover": { backgroundColor: "#f5f5f5" },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" fontWeight="bold">
          {title}
        </Typography>
        <IconButton onClick={handleMarkAsDeleted} size="small">
          <DeleteIcon />
        </IconButton>
      </Box>
      <Typography variant="body2" color="text.secondary">
        {message}
      </Typography>
      <Typography variant="caption" color="gray">
        {formattedDate}
      </Typography>
    </Box>
  );
};

export default NotificationCard;
