import React, { useEffect, useState } from "react";
import { Drawer, Box, Tabs, Tab, Switch, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { useGetAllCourses, useGetAllEventsOfCourse } from "../hooks/queries/CourseQuery";
import { useGetCurrentUsersTimetable } from "../hooks/queries/useLoginQuery";
import { useGetAllUsersSwapRequest, useDeleteSwapRequest } from "../hooks/queries/SwapQuery";
import { v1 as uuid } from "uuid";
import { CourseGUI, TimetableEvent } from "../types/types";
import SwapComponent from "./SwapComponent";

type SidebarProps = {
  isOpen: boolean;
  toggleSidebar: () => void;
  onAddEvents: (newEvents: TimetableEvent[]) => void;
  onRemoveEvents: (oldEvents: TimetableEvent[]) => void;
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar, onAddEvents, onRemoveEvents }) => {
  const [originalCourses, setOriginalCourses] = useState<CourseGUI[]>([]);
  const [originalEvents, setOriginalEvents] = useState<TimetableEvent[]>();
  const [activeTab, setActiveTab] = useState(0);
  const [additionalCourses, setAdditionalCourses] = useState<CourseGUI[]>([]);
  const [subjectToggles, setSubjectToggles] = useState<{ [key: string]: boolean }>({});
  const [options, setOptions] = useState<CourseGUI[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<CourseGUI | null>(null);

  const getUsersCourses = useGetCurrentUsersTimetable();
  const getAllCourses = useGetAllCourses();
  const getAllEventsOfCourse = useGetAllEventsOfCourse();
  const getAllUsersSwapRequest = useGetAllUsersSwapRequest();
  const { mutate: fetchSwapRequests } = getAllUsersSwapRequest;
  const deleteSwapRequest = useDeleteSwapRequest();

  useEffect(() => {
    if (isOpen) {
      fetchSwapRequests();
    }
  }, [isOpen, fetchSwapRequests]);

  const dayMap: { [key: number]: string } = {
    1: "Pondelok",
    2: "Utorok",
    3: "Streda",
    4: "Štvrtok",
    5: "Piatok",
  };

  const formatTime = (time: number) => `${time}:00`;

  useEffect(() => {
    if (getUsersCourses.data) {
      const eventList = Array.isArray(getUsersCourses.data)
        ? getUsersCourses.data
        : Array.isArray((getUsersCourses.data as any)?.timetableEvents)
          ? (getUsersCourses.data as any).timetableEvents
          : [];

      setOriginalEvents(eventList);

      const courseNames = eventList.map((x: TimetableEvent) => ({
        name: x.course.name,
        code: x.course.code,
      } as CourseGUI));

      const uniqueCourses: CourseGUI[] = courseNames
        .filter((course: CourseGUI, index: number, self: CourseGUI[]) =>
          self.findIndex((c) => c.name === course.name && c.code === course.code) === index
        )
        .sort((a: CourseGUI, b: CourseGUI) => a.name.localeCompare(b.name));

      setOriginalCourses(uniqueCourses);

      setSubjectToggles((prev) => {
        const newToggles = { ...prev };

        uniqueCourses.forEach((course) => {
          if (!(course.code in prev)) {
            newToggles[course.code] = false;
          }
        });

        return newToggles;
      });

      setAdditionalCourses((prevSubjects) =>
        prevSubjects.filter((prev) => uniqueCourses.some((orig) => prev.code === orig.code))
      );
    }
  }, [getUsersCourses.data]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleDeleteSwap = (id: string) => {
    deleteSwapRequest.mutate(id, {
      onSuccess: () => {
        fetchSwapRequests();
      },
    });
  };

  useEffect(() => {
    const areAllTogglesInactive = Object.values(subjectToggles).every((toggle) => !toggle);
    (window as any).setIsOriginalTimetable(areAllTogglesInactive);
  }, [subjectToggles]);

  const handleSwitchChange = (subjectCode: string) => {
    setSubjectToggles((prev) => {
      const isNowActive = !prev[subjectCode];
      const originalCourseNames = new Set(Array.from(originalEvents || []).map((event) => event.course.name));
      let eventList = [];

      if (originalCourseNames.has(subjectCode)) {
        eventList = originalEvents?.filter((event) => event.course.code === subjectCode) || [];
        onAddEvents(eventList);
      } else {
        getAllEventsOfCourse.mutate(subjectCode, {
          onSuccess: (data) => {
            eventList = Array.isArray(data)
              ? data
              : Array.isArray((data as any)?.timetableEvents)
                ? (data as any).timetableEvents
                : [];

            if (isNowActive) {
              const filteredFetchedEvents = eventList.filter((fetchedEvent: TimetableEvent) => {
                return !Array.from(originalEvents || []).some((originalEvent) =>
                  originalEvent.day === fetchedEvent.day &&
                  originalEvent.course.code === fetchedEvent.course.code &&
                  originalEvent.type === fetchedEvent.type &&
                  !(fetchedEvent.startTime >= originalEvent.startTime + originalEvent.duration ||
                    fetchedEvent.startTime + fetchedEvent.duration <= originalEvent.startTime)
                );
              });

              const mergeFetchedEvents = (eventList: TimetableEvent[]): TimetableEvent[] => {
                const mergedEvents: TimetableEvent[] = [];

                eventList.forEach((event) => {
                  let merged = false;

                  for (let i = 0; i < mergedEvents.length; i++) {
                    const mergedEvent = mergedEvents[i];
                    const mergedEndTime = mergedEvent.startTime + mergedEvent.duration;
                    const eventEndTime = event.startTime + event.duration;

                    if (
                      mergedEvent.day === event.day &&
                      mergedEvent.course.name === event.course.name &&
                      mergedEvent.type === event.type &&
                      mergedEvent.room.name === event.room.name &&
                      (event.startTime < mergedEndTime && eventEndTime >= mergedEvent.startTime)
                    ) {
                      mergedEvent.startTime = Math.min(mergedEvent.startTime, event.startTime);
                      mergedEvent.duration = Math.max(mergedEndTime, eventEndTime) - mergedEvent.startTime;

                      event.teachers.forEach(teacher => {
                        if (!mergedEvent.teachers.find(t => t.fullName === teacher.fullName)) { mergedEvent.teachers.push(teacher); }
                      });

                      merged = true;
                      break;
                    }
                  }

                  if (!merged) {
                    mergedEvents.push({ ...event, teachers: [...event.teachers] });
                  }
                });

                return mergedEvents;
              };

              const mergedFetchedEvents = mergeFetchedEvents(filteredFetchedEvents);

              onAddEvents(mergedFetchedEvents);
            } else {
              onRemoveEvents(eventList);
            }
          },
        });
      }

      return {
        ...prev,
        [subjectCode]: isNowActive,
      };
    });
  };

  const handleAddSubject = () => {
    if (selectedCourse && ![...originalCourses, ...additionalCourses].some((s) => s.code === selectedCourse.code)) {
      getAllEventsOfCourse.mutate(selectedCourse.code, {
        onSuccess: (data) => {
          setAdditionalCourses((prev) => [...prev, selectedCourse].sort((a, b) => a.name.localeCompare(b.name)));
          setSubjectToggles((prev) => ({ ...prev, [selectedCourse.code]: true }));
          onAddEvents(data);
        },
      });
    }
  };

  const handleDeleteSubject = (subjectCode: string) => {
    getAllEventsOfCourse.mutate(subjectCode, {
      onSuccess: (data) => {
        setAdditionalCourses(additionalCourses.filter((course) => course.code !== subjectCode));
        setSubjectToggles((prev) => {
          const newToggles = { ...prev };
          delete newToggles[subjectCode];
          return newToggles;
        });
        onRemoveEvents(data);
      },
    });
  };

  const handleInputChangeComboBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      getAllCourses.mutate(value, {
        onSuccess: (data) => {
          const uniqueData = data.reduce((acc: typeof data, item) => {
            if (!acc.some((existingItem) => existingItem.name === item.name)) {
              acc.push({
                ...item,
                name: item.name.length > 50 ? item.name.slice(0, 50) + "..." : item.name,
              });
            }
            return acc;
          }, []);
          setOptions(uniqueData);
        },
      });
    } else {
      setOptions([]);
    }
  };

  const resetSelectedCoursesFn = () => {
    setSubjectToggles((prev) => {
      const newToggles = { ...prev };
      Object.keys(newToggles).forEach((key) => newToggles[key] = false);
      return newToggles;
    });
  };

  (window as any).resetSelectedCourses = resetSelectedCoursesFn;

  return (
    <Drawer anchor="left" open={isOpen} onClose={toggleSidebar}>
      <Box sx={{ width: 300, padding: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="inherit"
          variant="fullWidth"
          sx={{
            mb: 2,
            "& .MuiTab-root": { color: "#5ebed7", backgroundColor: "white" },
            "& .Mui-selected": { color: "white", backgroundColor: "#5ebed7" },
            "& .MuiTabs-indicator": { backgroundColor: "#5ebed7" },
          }}
        >
          <Tab label="Predmety" />
          <Tab label="Výmeny" />
        </Tabs>

        <Box>
          {activeTab === 0 && (
            <Box>
              <Box display="flex" alignItems="center" mb={2}>
                <Combobox value={selectedCourse} onChange={(value) => setSelectedCourse(value)}>
                  <ComboboxInput
                    displayValue={(course: CourseGUI) => course?.name}
                    placeholder="Hľadať predmet"
                    onChange={handleInputChangeComboBox}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-black"
                  />
                  <ComboboxOptions
                    anchor="bottom"
                    className="absolute bg-white border block rounded-lg border-gray-300 text-gray-900 text-sm"
                    style={{ zIndex: 1300, width: 300 }}
                  >
                    {options.map((course) => (
                      <ComboboxOption key={uuid()} value={course}>
                        {`${course?.name} (${course?.facultyName})`}
                      </ComboboxOption>
                    ))}
                  </ComboboxOptions>
                </Combobox>

                <IconButton
                  sx={{ ml: 1, backgroundColor: "#5ebed7", color: "white", "&:hover": { backgroundColor: "#4aa8c3" } }}
                  onClick={handleAddSubject}
                >
                  <AddIcon />
                </IconButton>
              </Box>

              {originalCourses.map((subject) => (
                <Box key={subject.code} display="flex" alignItems="center" width="100%" marginBottom="4px" sx={{ border: "1px solid black", borderRadius: "8px", padding: "2px" }}>
                  <Switch
                    checked={subjectToggles[subject.code] || false}
                    onChange={() => handleSwitchChange(subject.code)}
                    sx={{ "& .MuiSwitch-thumb": { backgroundColor: "#5ebed7" }, "& .Mui-checked": { color: "#5ebed7" } }} />
                  <Typography variant="body1" ml={2} width="100%">
                    {subject.name}
                  </Typography>
                </Box>
              ))}

              {additionalCourses.map((subject) => (
                <Box key={subject.code} display="flex" alignItems="center" width="100%" marginBottom="4px" sx={{ border: "1px solid black", borderRadius: "8px", padding: "2px" }}>
                  <Switch
                    checked={subjectToggles[subject.code] || false}
                    onChange={() => handleSwitchChange(subject.code)}
                    sx={{ "& .MuiSwitch-thumb": { backgroundColor: "#5ebed7" }, "& .Mui-checked": { color: "#5ebed7" } }} />
                  <Typography variant="body1" ml={2} width="100%">
                    {subject.name}
                  </Typography>
                  <IconButton sx={{ ml: 1, color: "#5ebed7" }} onClick={() => handleDeleteSubject(subject.code)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}

          {activeTab === 1 && (
            <Box sx={{ mt: 2 }}>
              {getAllUsersSwapRequest.isError && <p>Nepodarilo sa načítať výmeny</p>}
              {getAllUsersSwapRequest.data && getAllUsersSwapRequest.data.length === 0 && (
                <p>Žiadne aktívne požiadavky na výmenu</p>
              )}
              {getAllUsersSwapRequest.data?.map((swap, index) => (
                <SwapComponent
                  key={index}
                  id={swap.swapId}
                  subject={swap.eventFrom.course.name}
                  originalDayOfWeek={`${dayMap[swap.eventFrom.day]} ${formatTime(swap.eventFrom.startTime)}`}
                  newDayOfWeek={`${dayMap[swap.eventTo.day]} ${formatTime(swap.eventTo.startTime)}`}
                  onDelete={() => handleDeleteSwap(swap.swapId)}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
