import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";

interface SwapComponentProps {
  id: string;
  subject: string;
  originalDayOfWeek: string; 
  newDayOfWeek: string; 
  onDelete: () => void;
}

const SwapComponent: React.FC<SwapComponentProps> = ({
  id,
  subject,
  originalDayOfWeek,
  newDayOfWeek,
  onDelete,
}) => (
  <Box
    sx={{
      p: 2,
      borderRadius: 1,
      mb: 1, 
      boxShadow: 0,
      backgroundColor: "white",
      "&:hover": { backgroundColor: "#f9f9f9" },
      cursor: "pointer",
      position: "relative" 
    }}
  >
    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
      {subject}
    </Typography>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ gap: 1 }}
    >
      <Box
        sx={{
          flex: "0 0 120px", 
          textAlign: "right",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: "0.85rem", whiteSpace: "nowrap" }}
        >
          {originalDayOfWeek}
        </Typography>
      </Box>
      
      <ArrowForwardIcon
        sx={{
          fontSize: 18,
        }}
      />
      
      <Box
        sx={{
          flex: "0 0 120px", 
          textAlign: "left",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: "0.85rem", whiteSpace: "nowrap" }}
        >
          {newDayOfWeek}
        </Typography>
      </Box>
    </Box>
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        onDelete();
      }}
      sx={{ position: "absolute", bottom: 8, right: 8 }}
    >
      <DeleteIcon />
    </IconButton>
  </Box>
);

export default SwapComponent;
