/* eslint-disable semi */
import { MappedTimeTableBlock, TimetableEvent } from "../types/types";
import Block from "./Block";
import React, { useMemo, useState, useEffect } from "react";
import BlockForm from "./Modals/BlockForm";
import BlockModal from "./Modals/BlockModal";
import { mapFormDataToTimetableEvent, useAddEvent } from "../hooks/queries/EventQuery";
import { useGetAllEventsOfCourse } from "../hooks/queries/CourseQuery";
import { useGetCurrentUsersTimetable } from "../hooks/queries/useLoginQuery";
import { useCreateSwapRequest } from "../hooks/queries/SwapQuery";
import { useGetUser } from "../hooks/queries/useLoginQuery";
import { v1 as uuid } from "uuid";
import ReactDOM from "react-dom";

type Attributes = {
  rows: string[];
  columns: string[];
  items: TimetableEvent[];
  onAddEvents: (newEvents: TimetableEvent[]) => void;
  onRemoveEvents: (oldEvents: TimetableEvent[]) => void;
};

const Grid: React.FC<Attributes> = ({ rows, columns, items, onAddEvents, onRemoveEvents }) => {
  console.log("Grid props:", { rows, columns, items });

  const initialFormData: TimetableEvent = {
    course: { code: "", shortcut: "", name: "", type: "" },
    room: { name: "", type: "" },
    teachers: [{ fullName: "", roles: [{ roleType: "" }], email: "" }],
    type: "",
    day: 1,
    startTime: 0,
    duration: 0,
    active: false,
  };

  const [dimmedBlocks, setDimmedBlocks] = useState<Set<string>>(new Set());
  const [allBlockIds, setAllBlockIds] = useState<Set<string>>(new Set());
  const [originalEvents, setOriginalEvents] = useState<TimetableEvent[]>([]);
  const [fetchedEvents, setFetchedEvents] = useState<TimetableEvent[]>([]);
  const [formData, setFormData] = useState<TimetableEvent>(initialFormData);
  const [isAddFormOpen, setAddFormOpen] = useState(false);
  const [isModalOpen, setIsBlockModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [selectedOriginalBlock, setSelectedOriginalBlock] = useState<TimetableEvent | null>(null);
  const createSwapRequest = useCreateSwapRequest();

  const gridEvents = useMemo(() => items.map((item) => ({ ...item, id: uuid() })), [items]);
  const getUsersCourses = useGetCurrentUsersTimetable();

  useEffect(() => {
    setAllBlockIds(new Set(gridEvents.map((item) => item.id)));
  }, [gridEvents]);

  useEffect(() => {
    if (getUsersCourses.data) {
      const eventList = Array.isArray(getUsersCourses.data)
        ? getUsersCourses.data
        : Array.isArray((getUsersCourses.data as any)?.timetableEvents)
          ? (getUsersCourses.data as any).timetableEvents
          : [];

      setOriginalEvents((prevEvents) => {
        if (eventList.length > 0) {
          return eventList;
        }
        return prevEvents;
      });
    }
  }, [gridEvents, getUsersCourses.data]);

  const blocks = useMemo(() => {
    return gridEvents.reduce((prev, curr, index) => {
      const currEnd = curr.startTime + curr.duration;

      const overlappingBlocks = gridEvents.filter((item) => {
        if (item.day === curr.day) {
          const itemEnd = item.startTime + item.duration;
          return Math.max(curr.startTime, item.startTime) < Math.min(currEnd, itemEnd);
        }
        return false;
      });

      const prevBlock = gridEvents[index - 1] || null;
      const nextBlock = gridEvents[index + 1] || null;

      let trueOverlapCount = overlappingBlocks.length;

      if (prevBlock && nextBlock) {
        const prevEnd = prevBlock.startTime + prevBlock.duration;
        const nextEnd = nextBlock.startTime + nextBlock.duration;

        const overlapsPrev = Math.max(curr.startTime, prevBlock.startTime) < Math.min(currEnd, prevEnd);
        const overlapsNext = Math.max(curr.startTime, nextBlock.startTime) < Math.min(currEnd, nextEnd);
        const prevAndNextOverlap = Math.max(prevBlock.startTime, nextBlock.startTime) < Math.min(prevEnd, nextEnd);

        if (overlapsPrev && overlapsNext && !prevAndNextOverlap) {
          trueOverlapCount--;
        }
      }

      const height = 1 / Math.max(1, trueOverlapCount);

      const updatedPrev = prev.map((block) => {
        if (overlappingBlocks.some((overlap) => overlap.id === block.id)) {
          return { ...block, height };
        }
        return block;
      });

      const updatedNext = updatedPrev.map((block) => {
        if (nextBlock && block.id === nextBlock.id) {
          return { ...block, height };
        }
        return block;
      });

      const usedOrders = new Set(
        updatedNext
          .filter((block) => overlappingBlocks.some((overlap) => overlap.id === block.id))
          .map((block) => block.order)
      );

      let order = 1;
      while (usedOrders.has(order)) {
        order++;
      }

      return [
        ...updatedNext,
        {
          id: curr.id,
          width: curr.duration,
          x: curr.startTime - 6,
          y: curr.day,
          order,
          height,
          blockInfo: {
            lessonShortcut: curr.course.shortcut,
            lectorName: curr.teachers.map((x) => x.fullName).join(", "),
            room: curr.room.name,
            lessonType: curr.type,
            duration: curr.duration,
            height,
          },
          timetableEvent: curr,
        },
      ];
    }, [] as MappedTimeTableBlock[]);
  }, [gridEvents]);

  const { data: userData } = useGetUser();
  const { mutate } = useAddEvent();
  const getAllEventsOfCourse = useGetAllEventsOfCourse();

  const handleAddFormSubmit = async (formData: TimetableEvent) => {
    if (userData) {
      try {
        const timetableEvent = mapFormDataToTimetableEvent(formData);
        mutate({ timetableEvent });
        setOriginalEvents((prevEvents) => [...prevEvents, formData]);
        console.log("Form submitted with data:", timetableEvent);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.error("User data not available");
    }
    setAddFormOpen(false);
  };

  const handleSwap = (event: TimetableEvent) => {
    if (event && event.type !== "lecture") {
      setDimmedBlocks(new Set(allBlockIds));
      setSelectedOriginalBlock(event);

      getAllEventsOfCourse.mutate(event.course.code, {
        onSuccess: (data) => {
          (window as any).setIsOriginalTimetable(false);

          const eventList = Array.isArray(data)
            ? data
            : Array.isArray((data as any)?.timetableEvents)
              ? (data as any).timetableEvents
              : [];

          const filteredFetchedEvents = eventList.filter((fetchedEvent: TimetableEvent) => {
            return !Array.from(originalEvents || []).some((originalEvent) =>
              originalEvent.day === fetchedEvent.day &&
              originalEvent.course.code === fetchedEvent.course.code &&
              !(fetchedEvent.startTime >= originalEvent.startTime + originalEvent.duration ||
                fetchedEvent.startTime + fetchedEvent.duration <= originalEvent.startTime)
            );
          });

          const mergeFetchedEvents = (eventList: TimetableEvent[]): TimetableEvent[] => {
            const mergedEvents: TimetableEvent[] = [];

            eventList.forEach((event) => {
              const overlapIndex = mergedEvents.findIndex((mergedEvent) => {
                const mergedEndTime = mergedEvent.startTime + mergedEvent.duration;
                const currentEndTime = event.startTime + event.duration;

                return (
                  mergedEvent.day === event.day &&
                  mergedEvent.course.code === event.course.code &&
                  mergedEvent.type === event.type &&
                  mergedEvent.room.name === event.room.name &&
                  event.startTime < mergedEndTime && currentEndTime >= mergedEvent.startTime
                );
              });

              if (overlapIndex !== -1) {
                const mergedEvent = mergedEvents[overlapIndex];
                const mergedEndTime = Math.max(mergedEvent.startTime + mergedEvent.duration, event.startTime + event.duration);
                mergedEvent.duration = mergedEndTime - mergedEvent.startTime;

                event.teachers.forEach(teacher => {
                  if (!mergedEvent.teachers.find(t => t.fullName === teacher.fullName)) { mergedEvent.teachers.push(teacher); }
                });
              } else {
                mergedEvents.push({ ...event });
              }
            });

            return mergedEvents;
          };

          if (filteredFetchedEvents.length === 0) {
            setDimmedBlocks(new Set());
            setFetchedEvents([]);
          }

          const mergedFetchedEvents = mergeFetchedEvents(filteredFetchedEvents);

          setFetchedEvents(mergedFetchedEvents);
          onAddEvents(mergedFetchedEvents);
        },
        onError: (error) => {
          console.error("Failed to fetch course blocks:", error);
        },
      });
    }
  };

  const handleBlockClick = (block: MappedTimeTableBlock) => {
    if (selectedOriginalBlock) {
      createSwapRequest.mutate(
        {
          eventFrom: mapFormDataToTimetableEvent(selectedOriginalBlock),
          eventTo: mapFormDataToTimetableEvent(block.timetableEvent),
        },
        {
          onSuccess: (response) => {
            console.log("Full Axios response:", response);

            if (response.status === 201) {
              console.log("Swap request created successfully with a match.");
              alert("Žiadosť o výmenu bola evidovaná a našli sme vhodnú výmenu!");
            } else {
              console.log("Swap request created successfully, but no match found.");
              alert("Žiadosť o výmenu bola evidovaná.");
            }

            clearSelectedEvents();
            (window as any).setIsOriginalTimetable(true);
          },
          onError: (error) => {
            console.error("Swap request failed:", error);
            alert(error);

            clearSelectedEvents();
            (window as any).setIsOriginalTimetable(true);
          },
        }
      );
    } else if (!dimmedBlocks.has(block.id)) {
      setFormData(block.timetableEvent);
      openBlockModal(block.x, block.y);
      clearSelectedEvents();
    }
  };

  const clearSelectedEvents = () => {
    setSelectedOriginalBlock(null);
    onRemoveEvents(fetchedEvents);
    setDimmedBlocks(new Set());
    setFetchedEvents([]);
  };

  const resetSelectedBlockFn = () => {
    clearSelectedEvents();
  };

  (window as any).resetSelectedBlock = resetSelectedBlockFn;

  const openBlockModal = (x: number, y: number) => {
    setModalPosition({ x, y });
    setIsBlockModalOpen(true);
  };

  const closeBlockModal = () => setIsBlockModalOpen(false);

  return (
    <div id="schedule" className="w-full h-full relative">
      <div
        className="grid grid-cols-15 w-full h-full"
        style={{
          gridTemplateColumns: `60px repeat(${columns.length - 1}, 1fr)`,
          gridTemplateRows: `60px repeat(${rows.length - 1}, 1fr)`,
        }}
      >
        {rows.map((row, rowIdx) =>
          columns.map((col, colIdx) => {
            if (rowIdx === 0) {
              return (
                <div
                  key={`cell-${rowIdx}-${colIdx}`}
                  className="bg-white border flex border-[#ccc]"
                  style={{ height: "60px" }}
                >
                  <p className="m-auto">{columns[colIdx]}</p>
                </div>
              );
            }

            if (colIdx === 0) {
              return (
                <div
                  key={`cell-${rowIdx}-${colIdx}`}
                  className="bg-white flex border border-[#ccc]"
                  style={{ width: "60px" }}
                >
                  <p className="m-auto">{rows[rowIdx]}</p>
                </div>
              );
            }

            return (
              <div
                key={`cell-${rowIdx}-${colIdx}`}
                id={`cell-${colIdx}-${rowIdx}`}
                className="bg-transparent relative border border-[#ccc]"
                style={{ minHeight: "100px" }}
                onClick={() => {
                  setFormData(initialFormData);
                  setAddFormOpen(true);
                }}
              />
            );
          })
        )}
      </div>
      {blocks.map((block, index) => {
        const isOriginal = originalEvents.find((event) => event.course === block.timetableEvent.course);
        const isEventsCountHigher = originalEvents.length < gridEvents.length;
        const isDimmed = dimmedBlocks.has(block.id) || (isOriginal && isEventsCountHigher);

        const blockDiv = (
          <div
            key={`block-${index}`}
            className={`absolute bg-blue-500 z-[1] ${isDimmed ? "opacity-60 cursor-not-allowed" : ""}`}
            style={{
              top: `${block.height * 100 * block.order - block.height * 100}%`,
              left: 1,
              height: `${block.height * 100}%`,
              width: `${block.width * 100}%`,
            }}
            onClick={() => handleBlockClick(block)}
          >
            <Block {...block.blockInfo} />
          </div>
        );

        const modalRoot = document.getElementById(`cell-${block.x}-${block.y}`);

        return modalRoot ? ReactDOM.createPortal(blockDiv, modalRoot) : null;
      })}

      <BlockForm
        isOpen={isAddFormOpen}
        onClose={() => setAddFormOpen(false)}
        onSubmit={handleAddFormSubmit}
        initialData={formData}
      />
      <BlockModal
        isOpen={isModalOpen}
        onClose={closeBlockModal}
        initialData={formData}
        position={modalPosition}
        onSwap={handleSwap}
      />
    </div>
  );
};

export default Grid;
