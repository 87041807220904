import React from "react";
import { Box, Popover, Typography } from "@mui/material";
import NotificationCard from "./NotificationCard";
import { useGetNotifications, useMarkNotificationAsRead } from "../hooks/queries/NotificationQuery";

const NotificationsDropdown: React.FC<{
  anchorEl: HTMLElement | null;
  onClose: () => void;
  setUnreadCount: (count: number) => void;
}> = ({ anchorEl, onClose, setUnreadCount }) => {
  const open = Boolean(anchorEl);
  const { data: notifications = [], isLoading, isError } = useGetNotifications();
  const { mutate: markAllAsRead } = useMarkNotificationAsRead();

  React.useEffect(() => {
    if (open && notifications.some(n => !n.isRead)) {
      markAllAsRead();
    }
  }, [open, notifications, markAllAsRead]);

  const unreadCount = notifications.filter((n) => !n.isRead).length;
  React.useEffect(() => {
    setUnreadCount(unreadCount);
  }, [unreadCount, setUnreadCount]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ mt: 1 }}
    >
      <Box sx={{ width: 300, p: 2, bgcolor: "#f9f9f9", borderRadius: 1 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Notifications
        </Typography>
        {isLoading ? (
          <Typography variant="body2" color="text.secondary">
            Loading notifications...
          </Typography>
        ) : isError ? (
          <Typography variant="body2" color="error">
            Failed to load notifications
          </Typography>
        ) : notifications.length > 0 ? (
          notifications.map((notification, index) => (
            <NotificationCard
              key={index}
              notification={notification}
            />
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">
            No new notifications
          </Typography>
        )}
      </Box>
    </Popover>
  );
};

export default NotificationsDropdown;
