import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import Navbar from "../components/Navbar";
import Timetable from "../components/Timetable";
import LoginModal from "../components/Modals/LoginModal";
import { useGetCurrentUsersTimetable, useGetUser } from "../hooks/queries/useLoginQuery";
import { TimetableEvent } from "../types/types";

const HomePage = () => {
  const { data: userData } = useGetUser();
  const { data: timetableData } = useGetCurrentUsersTimetable();

  const [addedEvents, setAddedEvents] = useState<TimetableEvent[]>([]);
  const [removedEvents, setRemovedEvents] = useState<TimetableEvent[]>([]);

  const addTimetableEvents = (newEvents: TimetableEvent[] | { timetableEvents: TimetableEvent[] }) => {
    if (Array.isArray(newEvents)) {
      setAddedEvents((prevEvents) => [...prevEvents, ...newEvents]);
    } else if (newEvents?.timetableEvents) {
      setAddedEvents((prevEvents) => [...prevEvents, ...newEvents.timetableEvents]);
    } else {
      console.error("Unexpected structure for newEvents:", newEvents);
    }
  };

  const removeTimetableEvents = (oldEvents: TimetableEvent[] | { timetableEvents: TimetableEvent[] }) => {
    let events: TimetableEvent[] = [];

    if (Array.isArray(oldEvents)) {
      events = oldEvents;
    } else if (oldEvents?.timetableEvents) {
      events = oldEvents.timetableEvents;
    } else {
      console.error("Unexpected structure for eventsToRemove:", oldEvents);
      return;
    }

    setRemovedEvents((prevRemovedEvents) => [...prevRemovedEvents, ...events]);

    setAddedEvents((prevAddedEvents) =>
      prevAddedEvents.filter(
        (event) =>
          !events.some(
            (removedEvent) =>
              removedEvent.course.name === event.course.name &&
              removedEvent.course.type === event.course.type &&
              removedEvent.day === event.day &&
              removedEvent.startTime === event.startTime
          )
      )
    );
  };

  const originalEvents = useMemo(() => {
    return timetableData ? timetableData.map((event) => ({ ...event, active: true })) : [];
  }, [timetableData]);

  const combinedEvents = useMemo(() => {
    const allEvents = [...originalEvents, ...addedEvents];
    return allEvents.filter(
      (event) =>
        !removedEvents.some(
          (removedEvent) =>
            removedEvent.course.name === event.course.name &&
            removedEvent.course.type === event.course.type &&
            removedEvent.day === event.day &&
            removedEvent.startTime === event.startTime &&
            removedEvent.room === event.room
        )
    );
  }, [originalEvents, addedEvents, removedEvents]);

  return (
    <Box textAlign="center" display="flex" flexDirection="column" className="h-screen">
      <Navbar events={combinedEvents} onAddEvents={addTimetableEvents} onRemoveEvents={removeTimetableEvents} onSetDefaultState={removeTimetableEvents} />
      <Timetable events={combinedEvents} addEvents={addTimetableEvents} removeEvents={removeTimetableEvents} />
      {userData?.personalNumber === "" && <LoginModal isOpen={true} />}
    </Box>
  );
};

export default HomePage;
