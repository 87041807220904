import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { TimetableEvent, blockModal } from "../../types/types";
import Close from "../Icons/Close";
import Edit from "../Icons/Edit";
import BlockForm from "./BlockForm";
import Swap from "../Icons/Swap";
import Delete from "../Icons/Delete";
import Location from "../Icons/Location";
import Person from "../Icons/Person";
import { useGetUser } from "../../hooks/queries/useLoginQuery";
import { mapFormDataToTimetableEvent, useAddEvent, useDeleteEvent, useEditEvent } from "../../hooks/queries/EventQuery";
import Add from "../Icons/Add";

const BlockModal: React.FC<blockModal> = ({ isOpen, onClose, initialData, position, onSwap }) => {
  const [formData, setFormData] = useState(initialData);
  const { data: userData } = useGetUser();
  const { mutate: editMutate } = useEditEvent();
  const { mutate: deleteMutate } = useDeleteEvent();
  const { mutate: addMutate } = useAddEvent();

  useEffect(() => {
    setFormData(initialData || {});
  }, [initialData]);

  const [isEditFormOpen, setEditFormOpen] = useState(false);

  const openEditForm = () => {
    setEditFormOpen(true);
  };

  const closeEditForm = () => {
    setEditFormOpen(false);
  };

  const handleEditFormSubmit = async (formData: TimetableEvent) => {
    try {
      if (userData) {
        const timetableEvent = mapFormDataToTimetableEvent(formData);
        editMutate({ formData: initialData, timetableEvent: timetableEvent });
        onClose();
      } else {
        console.error("User data not available");
      }
    } catch (error) {
      console.log(error);
    }
    closeEditForm();
  };

  const handleAdd = async (formData: TimetableEvent) => {
    if (userData) {
      try {
        const timetableEvent = mapFormDataToTimetableEvent(formData);
        addMutate({ timetableEvent });
        console.log("Adding event: ", timetableEvent);
        onClose();
      } catch (error) {
        console.log(error);
      }
    } else {
      console.error("User data not available");
    }
  };

  const handleSwap = async () => {
    try {
      if (onSwap) {
        onSwap(initialData);
      }
      onClose();
      console.log("Handle swap for", formData.course.name);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      if (userData) {
        console.log(formData);
        deleteMutate({ formData });
        onClose();
        console.log("Handle delete for", formData.course.name);
      } else {
        console.error("User data not available");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getModalColor = (type: string) => {
    switch (type) {
      case "lecture":
        return "bg-lecture";
      case "laboratory":
        return "bg-laboratory";
      case "exercise":
        return "bg-exercise";
      default:
        return "bg-default";
    }
  };

  const modalWidth = 400; // Approximate modal width
  const modalHeight = 300; // Approximate modal height

  const adjustedPosition = {
    x: position.x * 100 + 100,
    y: position.y * 100 + 100,
  };

  // Check if modal overflows and adjust
  if (adjustedPosition.x + modalWidth > window.innerWidth) {
    adjustedPosition.x -= 200; // Move left
  }
  if (adjustedPosition.y + modalHeight > window.innerHeight) {
    adjustedPosition.y -= 100; // Move up
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="absolute p-0 bg-transparent shadow-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      contentLabel="Block Modal"
      style={{
        content: {
          top: adjustedPosition.y,
          left: adjustedPosition.x,
          border: "none",
          padding: 0,
          background: "transparent",
        },
      }}
    >
      <div id="block-modal" className="inline-block max-w-fit">
        {/* Modal Header */}
        <div className={`p-4 ${getModalColor(formData.type)}`}>
          {initialData?.active ? (
            <div className="flex justify-end space-x-2">
              <button type="button" className="hover:bg-gray-200 p-2" onClick={handleSwap}>
                <Swap />
              </button>
              <button type="button" className="hover:bg-gray-200 p-2" onClick={openEditForm}>
                <Edit />
              </button>
              <button type="button" className="hover:bg-gray-200 p-2" onClick={handleDelete}>
                <Delete />
              </button>
              <button type="button" className="hover:bg-gray-200 p-2" onClick={onClose}>
                <Close />
              </button>
            </div>
          ) : (
            <div className="flex justify-end space-x-2">
              <button type="button" className="hover:bg-gray-200 p-2" onClick={() => handleAdd(formData)}>
                <Add />
              </button>
              <button type="button" className="hover:bg-gray-200 p-2" onClick={onClose}>
                <Close />
              </button>
            </div>
          )}
          <div className="mt-2">
            <h2 className="text-xl font-bold text-black">{formData?.course?.name || "No course name"}</h2>
          </div>
        </div>

        {/* Modal Body */}
        <div className="p-4 bg-white">
          <div className="flex items-center space-x-2">
            <Location />
            <p className="text-lg font-semibold text-black">{formData?.room?.name || "Not available"}</p>
          </div>
          <div className="flex items-center space-x-2 mb-4">
            <Person />
            <p className="text-lg font-semibold text-black">
              {formData?.teachers?.map((x) => x.fullName).join(", ") || "Not available"}
            </p>
          </div>
        </div>
      </div>

      {/* Edit Form */}
      <BlockForm
        isOpen={isEditFormOpen}
        onClose={closeEditForm}
        onSubmit={handleEditFormSubmit}
        initialData={formData}
      />
    </Modal>
  );
};

export default BlockModal;
